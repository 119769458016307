<!--  -->
<template>
  <div class="">
    <Breadcrumb :getNav="getNav" :info="'产品详情'"></Breadcrumb>
    <el-card>
      <el-tabs>
        <el-tab-pane label="产品信息与手册">
          <RecipeList />
          <Recipes :info="info" />
        </el-tab-pane>
        <el-tab-pane label="产品说明">
          <Procedure :info="info" />
        </el-tab-pane>
      </el-tabs>
      <div style="height: 2px"></div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getListInfo } from "@/api/psi/product.js";
import Recipes from "@/components/productInfo/Recipes.vue";
import Procedure from "@/components/productInfo/Procedure.vue";
import RecipeList from "@/components/productInfo/RecipeList.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Recipes, Procedure, RecipeList },
  data() {
    //这里存放数据
    return {
      srcList: [],
      getNav: [],
      info: {},
      dataInfo: {},
      userdelinfo: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.info = this.$route.query;
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getNav = this.$getNavn("/product");
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.item-info {
  width: 100%;
  display: flex;
  justify-items: center;
}
.image-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebeef5;
  border-top: none;
}
.image-item > span {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  background: #fafafa;
  padding: 12px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
</style>