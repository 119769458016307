<!--  -->
<template>
  <div class="">
    <el-descriptions title="产品信息" border :column="3">
      <el-descriptions-item label="产品id">{{
        dataInfo.id
      }}</el-descriptions-item>
      <el-descriptions-item label="产品名称">{{
        dataInfo.name
      }}</el-descriptions-item>
      <el-descriptions-item label="建议零售价"
        >{{ dataInfo.price }}
      </el-descriptions-item>
      <el-descriptions-item label="所属类型">{{
        dataInfo.type_id
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag type="success" v-show="dataInfo.state">显示</el-tag>
        <el-tag type="warning" v-show="!dataInfo.state"
          >隐藏</el-tag
        ></el-descriptions-item
      >
      <el-descriptions-item label="添加者ID">{{
        dataInfo.u_id
      }}</el-descriptions-item>
      <el-descriptions-item label="产品详情">{{
        dataInfo.info
      }}</el-descriptions-item>
    </el-descriptions>
    <div class="item-info">
      <div class="image-item">
        <span>产品图</span>
        <el-image
          style="width: 50%"
          :src="dataInfo.img"
          :preview-src-list="srcList"
        ></el-image>
      </div>
      <div class="image-item">
        <span>产品详情图</span>
        <el-image
          style="width: 50%"
          :src="dataInfo.info_img"
          :preview-src-list="srcList"
        ></el-image>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getListInfo } from "@/api/psi/product.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      srcList: [],
      getNav: [],
      info: {},
      dataInfo: {},
      userdelinfo: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async catalogue() {
      let info = await getListInfo(this.info);
      this.dataInfo = info.data.data;
      this.srcList.push(this.dataInfo.img);
      this.srcList.push(this.dataInfo.info_img);
      console.log(this.srcList);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.info = this.$route.query;
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
    this.getNav = this.$getNavn("/product");
    console.log(this.info);
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.item-info {
  width: 100%;
  display: flex;
  justify-items: center;
}
.image-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebeef5;
  border-top: none;
}
.image-item > span {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 700;
  background: #fafafa;
  padding: 12px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}
</style>