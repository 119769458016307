import { post } from '@/utils/request'

//获取列表
export function getList(data) {
    return post('psi/product/listProduct', data)
}
//添加产品
export function getAdd(data) {
    return post('psi/product/addProduct', data)
}
//修改产品
export function getEdit(data) {
    return post('psi/product/editProduct', data)
}
//删除产品
export function getDel(data) {
    return post('psi/product/delProduct', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/classify/downward', data)
}
//产品详情
export function getListInfo(data) {
    return post('psi/product/infoProduct', data)
}
//手册详情
export function getInfoRecipe(data) {
    return post('psi/recipe/infoRecipe', data)
}
//手册添加
export function getAddRecipes(data) {
    return post('psi/recipe/addRecipe', data)
}
//手册修改
export function getEditRecipe(data) {
    return post('psi/recipe/editRecipe', data)
}
//手册删除
export function getDelRecipe(data) {
    return post('psi/recipe/delRecipe', data)
}
//说明信息
export function getInfoProcedure(data) {
    return post('psi/recipe/infoProcedure', data)
}
//说明删除
export function getDelProcedure(data) {
    return post('psi/recipe/delProcedure', data)
}
//说明修改
export function getEditProcedure(data) {
    return post('psi/recipe/editProcedure', data)
}
//说明添加
export function getAddProcedure(data) {
    return post('psi/recipe/addProcedure', data)
}
export function getState(data) {
    return post('psi/product/productSta', data)
}


