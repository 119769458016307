<!--  -->
<template>
  <div class="home">
    <el-dialog :visible.sync="dialogFormVisible" @close="userClose()">
      <div slot="title" v-show="open">新增出品流程</div>
      <div slot="title" v-show="!open">编辑出品流程</div>
      <el-form :model="form">
        <el-form-item label="出品流程" :label-width="formLabelWidth">
          <el-input v-model="form.info" placeholder="出品流程"></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setOpen()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 表格 -->
    <el-dialog :visible.sync="dialogFormVisible2" @close="userClose()">
      <div slot="title">删除流程</div>
      删除流程:{{ userdelinfo.info }}
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="setDel()">确 定</el-button>
      </div>
    </el-dialog>
    <div style="font-size: 16px; font-weight: 700; padding: 10px 0">
      出品流程
    </div>
    <el-button
      type="primary"
      @click="addOpen(true)"
      size="small"
      style="margin-bottom: 10px"
      >添加流程</el-button
    >

    <el-table :data="tableData" border style="width: 100%"  height="300">
      <el-table-column type="index" width="55"> </el-table-column>
      <el-table-column prop="info" label="内容"> </el-table-column>
      <el-table-column label="操作"  width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="addOpen(false, scope.row)"
          ></el-button>
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="delOpen(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getInfoProcedure,
  getDelProcedure,
  getEditProcedure,
  getAddProcedure,
} from "@/api/psi/product.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  props: {
    info: {},
  },
  components: {},
  data() {
    //这里存放数据
    return {
      formLabelWidth: "100px",
      open: true,
      dialogFormVisible2: false,
      dialogFormVisible: false,
      options: [],
      userdelinfo: {},
      tableData: [],
      form: {
        pm_id: "",
        state: 1,
        info: "",
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async addOpen(state, info) {
      this.form.pm_id = this.info.id;
      this.open = state;
      console.log(state);
      if (!state) {
        this.userdelinfo = info;
        this.form.id = info.id;
        this.form.info = info.info;
        console.log(this.form);
      }
      this.dialogFormVisible = true;
    },
    async setOpen() {
      let info = null;
      let state = this.open;
      if (state) {
        info = await getAddProcedure(this.form);
        console.log("增加");
      } else {
        let form = this.form;
        console.log(this.form);
        form.id = this.userdelinfo.id;
        info = await getEditProcedure(form);
        console.log("编辑");
      }
      this.judge(info, true);
    },
    delOpen(info) {
      this.dialogFormVisible2 = true;
      this.userdelinfo = info;
    },
    async setDel() {
      let info = await getDelProcedure({ id: this.userdelinfo.id });
      this.judge(info, 0);
    },
    judge(info, change, list) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        if (list == null) {
          this.catalogue();
        }
        if (change) {
          this.dialogFormVisible = false;
        } else {
          this.dialogFormVisible2 = false;
        }
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: "error",
        });
        return;
      }
      if (list == null) {
        this.catalogue();
      }
    },
    userClose() {
      this.form = {
        pm_id: this.info.id,
        state: 1,
        info: "",
      };
    },
    async catalogue() {
      let info = await getInfoProcedure(this.info);
      this.tableData = info.data.data.operate;
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.home {
  width: 100%;
}
</style>